body {
    margin: 0;
    -webkit-overflow-scrolling: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    width: 100vw;
    height: 100%; /* ios Safari measures 100vh to entire screen even if safari nav takes up some */
}

body button {
    font: inherit;
}

#root {
    position: absolute;
    inset: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.line_ellipsis_3 {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
}

.line_ellipsis_4 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

*::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background-color: transparent;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.2);    /* color of the scroll thumb */
    border-radius: 6px;       /* roundness of the scroll thumb */
    width: 8px;
}
